(function () {
  var windowsize = $(window).width();
  $('a[href^="#"]').click(function (e) {
    // Prevent the jump and the #hash from appearing on the address bar
    e.preventDefault();
    // Scroll the window, stop any previous animation, stop on user manual scroll
    // Check https://github.com/flesler/jquery.scrollTo for more customizability
    $(window)
      .stop(true)
      .scrollTo(this.hash, { duration: 1000, interrupt: true });
  });
  if (windowsize >= 1024) {
    $("#logo").on("click", function () {
      $(".current").removeClass("current");
    });
    $("nav ul li a").on("click", function () {
      $(".current").removeClass("current");
      $(this).addClass("current");
    });
  }
  if (windowsize <= 599) {
    // mobile navigation hide
    $("nav").hide();
    $("#menubutton").on("click", function () {
      $("nav").slideToggle("slow");
    });
    $("#logo").on("click", function () {
      $(".current").removeClass("current");
      $("nav").slideUp("slow");
    });
    $("nav ul li a").on("click", function () {
      $(".current").removeClass("current");
      $(this).addClass("current");
      $("nav").slideUp("slow");
    });
  }
})();

// scroll
(function () {
  var s = skrollr.init();
  /*$.stellar({
        horizontalScrolling: false,
        positionProperty: 'limit'
      });*/
})();
